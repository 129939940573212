// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer.js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-doctor-js": () => import("./../../../src/pages/doctor.js" /* webpackChunkName: "component---src-pages-doctor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-product-product-js": () => import("./../../../src/templates/product/product.js" /* webpackChunkName: "component---src-templates-product-product-js" */)
}

